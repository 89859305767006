export const FirebaseConfig = {
	"projectId": "esmart-test",
	"appId": "1:326572075814:web:f016d812ecea4564ee6fe7",
	"databaseURL": "https://esmart-test-default-rtdb.firebaseio.com",
	"storageBucket": "esmart-test.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBBcL724kzP-l5KUEvEab8XX4yBYoQ9G-A",
	"authDomain": "esmart-test.firebaseapp.com",
	"messagingSenderId": "326572075814",
	"measurementId": "G-MW1DK5DF47"
};